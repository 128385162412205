import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-snackbar-messages',
  templateUrl: './snackbar-messages.component.html',
  styleUrls: ['./snackbar-messages.component.scss']
})
export class SnackbarMessagesComponent implements OnInit {
  snackbarSuccess;
  snackbarError;
  snackbarErrorFile
  constructor(
    private snackbar: MatSnackBar,
    private translate: TranslateService,

  ) {
    this.snackbarSuccess = translate.onLangChange.subscribe(() => {
      this.translateSnackbarSuccess()
    })
    this.snackbarError = translate.onLangChange.subscribe(() => {
      this.translateSnackbarError()
    })
    this.snackbarErrorFile = translate.onLangChange.subscribe(() => {
      this.translateSnackbarErrorFile()
    })
  }

  ngOnInit(): void {
  }

  translateSnackbarSuccess(): any {
    this.translate.get('snackbar').subscribe(resp => {
      this.snackbarSuccess = resp.success
      this.snackbarSuccess = this.translate.instant("snackbar").success
    })
  }
  translateSnackbarError(): any {
    this.translate.get('snackbar').subscribe(resp => {
      this.snackbarError = resp.error
    })
  }
  translateSnackbarErrorFile(): any {
    this.translate.get('snackbar').subscribe(resp => {
      this.snackbarErrorFile = resp['error-file']
    })
  }

  onSuccess(): any {
    this.translateSnackbarSuccess()
    this.snackbar.open(this.snackbarSuccess, 'Close', { duration: 3000, panelClass: 'success-snackbar' });
  }

  onError(): any {
    this.translateSnackbarError()
    this.snackbar.open(this.snackbarError, 'Close', { duration: 3000, panelClass: 'danger-snackbar' });
  }

  onErrorFile(): any {
    this.translateSnackbarErrorFile()
    this.snackbar.open(this.snackbarErrorFile, 'Close', { duration: 3000, panelClass: 'danger-snackbar' });

  }

}
