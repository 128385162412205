import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticleService } from 'src/app/article/_services/article.service';
import { ContestService } from 'src/app/contest/_services/contest.service';
import { ProfileService } from 'src/app/profile/_services/profile.service';
import { ProjectService } from 'src/app/project/_services/project.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DialogConfirmComponent } from 'src/app/common/dialog-confirm/dialog-confirm.component';
import { DashboardService } from '../../_services/dashboard.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddContactModalComponent } from '../add-contact-modal/add-contact-modal.component';
import { ExpertPublicProfileComponent } from '../expert-public-profile/expert-public-profile.component';
import { BehaviorSubject } from 'rxjs';

export interface Category {
  name: string;
  filters: Filter[];
  results?: Result[];
  isProfile?: boolean;
}

export interface Filter {
  key: string;
  name: string;
  type: string;
  dropdownValues?: string[];
  tagsValues?: string[];
  selectable: boolean;
  removable: boolean;
  addOnBlur: boolean;
}

export interface Result {
  title: string;
  abstract: string;
  category: string;
  subCategory?: string;
  keywords?: string[];
  authors?: string[];
  requirements?: any[];
  rewardHeader?: string;
  reward?: string;
}


@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss'],
  providers: [DialogConfirmComponent]
})
export class BrowseComponent implements OnInit {
  someResults = new BehaviorSubject<any[]>([]);
  formGroupCategory: FormGroup;
  selectedCategoryIndex = 0;
  selectedCategory = '';
  categoryData: Category;
  categoriesNames = [
    'contest',
    'project',
    'article',
    'entity',
    // 'Mentor',
    'talent',
  ];
  numberOfResults = 10;
  profile;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private contestService: ContestService,
    private projectService: ProjectService,
    private articleService: ArticleService,
    private profileService: ProfileService,
    private dashService: DashboardService,
    private router: Router,
    public dialog: MatDialog,
    private confirmDialog: DialogConfirmComponent
  ) {
    this.selectedCategory = this.route.snapshot.paramMap.get('mainCategory')
      ? this.route.snapshot.paramMap.get('mainCategory')
      : this.route.snapshot.queryParams['mainCategory'];
    if (this.selectedCategory)
      this.selectedCategoryIndex = this.categoriesNames.findIndex(
        (categoryName) => categoryName == this.selectedCategory
      );
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    window.location.reload();
  }

  // non reactive
  // async ngOnInit(): Promise<void> {
  //   this.getCategoryData(this.selectedCategory);
  //   this.profileService.getUserProfile().subscribe((resp) => {
  //     this.profile = resp.data
  //   })
  // }

  async ngOnInit() {
    this.formGroupCategory = this.formBuilder.group({});
    await this.getCategoryData(this.selectedCategory);
    this.profileService.getUserProfile().subscribe((resp) => {
      this.profile = resp.data
    });

    await this.getSomeResults()
  }

  getSomeResults() {
    const payload = {
      ...this.formGroupCategory.value,
      category: this.categoryData?.name
    }

    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        payload[key] !== null ? payload[key] = payload[key].trim() : null
      }
    }

    const data = this.profileService.filter(payload).subscribe(res => {
      this.someResults = res.data
    })
  }
  onScroll(): void {
    this.numberOfResults += 5;
    this.getCategoryData(this.selectedCategory);
  }


  // non reactive categoryData

  // async getCategoryData(categoryName) {

  //   const filtersValues = this.getParameters();
  //   this.categoryData = await this.prepareCategoryData(
  //     categoryName,
  //     filtersValues
  //   );
  //   const formGroup = this.formBuilder.group({});

  //   this.categoryData?.filters?.map((filter) => {
  //     if (filter.type == 'tags') {

  //       if (typeof filtersValues[filter.key] === 'string')
  //         filtersValues[filter.key] = Array(filtersValues[filter.key])

  //       filtersValues[filter.key]?.forEach((element) => {
  //         filter.tagsValues.push(element);
  //       });
  //     }
  //     formGroup.addControl(
  //       filter.key,
  //       new FormControl(filtersValues[filter.key])
  //     );
  //   });

  //   this.formGroupCategory = formGroup;
  // }

  async getCategoryData(categoryName) {
    const filtersValues = this.formGroupCategory.value;
    this.categoryData = await this.prepareCategoryData(
      categoryName,
      filtersValues
    );
    this.categoryData?.filters?.map((filter) => {
      if (filter.type == 'tags') {

        if (typeof filtersValues[filter.key] === 'string')
          filtersValues[filter.key] = Array(filtersValues[filter.key])

        filtersValues[filter.key]?.forEach((element) => {
          filter.tagsValues.push(element);
        });
      }
      if (!this.formGroupCategory.contains(filter.key)) {
        this.formGroupCategory.addControl(
          filter.key,
          new FormControl(filtersValues[filter.key])
        );
      }
    });
  }

  getParameters() {
    const filtersValues = {};
    filtersValues['mainCategory'] =
      this.route.snapshot.queryParams['mainCategory'];

    switch (filtersValues['mainCategory']) {
      case 'contest':
        filtersValues['category'] = this.route.snapshot.queryParams['category'];
        filtersValues['subcategory'] =
          this.route.snapshot.queryParams['subcategory'];
        filtersValues['education'] =
          this.route.snapshot.queryParams['education'];
        filtersValues['skills'] = this.route.snapshot.queryParams['skills'];
        filtersValues['reward'] = this.route.snapshot.queryParams['reward'];
        filtersValues['keywords'] = this.route.snapshot.queryParams['keywords'];
      case 'project':
        filtersValues['category'] = this.route.snapshot.queryParams['category'];
        filtersValues['subcategory'] =
          this.route.snapshot.queryParams['subcategory'];
        filtersValues['education'] =
          this.route.snapshot.queryParams['education'];
        filtersValues['skills'] = this.route.snapshot.queryParams['skills'];
        filtersValues['reward'] = this.route.snapshot.queryParams['reward'];
        filtersValues['keywords'] = this.route.snapshot.queryParams['keywords'];
        break;

      case 'article':
        filtersValues['category'] = this.route.snapshot.queryParams['category'];
        filtersValues['author'] = this.route.snapshot.queryParams['author'];
        filtersValues['keywords'] = this.route.snapshot.queryParams['keywords'];
        break;

      case 'entity':
        filtersValues['orgname'] = this.route.snapshot.queryParams['orgname'];
        filtersValues['industry'] = this.route.snapshot.queryParams['industry'];
        filtersValues['description'] =
          this.route.snapshot.queryParams['description'];
        break;

      case 'mentor':
        filtersValues['university'] = this.route.snapshot.queryParams['university'];
        filtersValues['degree'] = this.route.snapshot.queryParams['degree'];
        filtersValues['field_of_study'] = this.route.snapshot.queryParams['field_of_study'];
        filtersValues['award'] = this.route.snapshot.queryParams['award'];
        filtersValues['keywords'] = this.route.snapshot.queryParams['keywords'];
        break;
      case 'talent':
        filtersValues['industry'] = this.route.snapshot.queryParams['industry'];
        filtersValues['university'] =
          this.route.snapshot.queryParams['university'];
        filtersValues['degree'] = this.route.snapshot.queryParams['degree'];
        filtersValues['field_of_study'] =
          this.route.snapshot.queryParams['field_of_study'];
        filtersValues['award'] = this.route.paramMap['award'];
        filtersValues['company'] = this.route.snapshot.queryParams['company'];
        filtersValues['keyword'] = this.route.snapshot.queryParams['keyword'];
        filtersValues['skills'] = this.route.snapshot.queryParams['skills'];
        filtersValues['name'] = this.route.snapshot.queryParams['name'];
        break;
    }
    return {
      ...filtersValues,
      num_recs: this.numberOfResults
    };
  }
  // window reload
  // submit(category): any {
  //   let params = {};
  //   params['mainCategory'] = category;

  //   if (this.formGroupCategory.valid) {
  //     this.categoryData.filters.forEach((filter) => {
  //       if (filter.type == 'tags') {
  //         this.formGroupCategory.controls[filter.key].setValue(
  //           this.categoryData.filters.find((x) => x.key == filter.key)
  //             .tagsValues
  //         );
  //       }
  //       params[filter.key] = this.formGroupCategory.controls[filter.key].value;
  //     });
  //     if (params) {
  //       this.router.navigate(['/browse'], { queryParams: params }).then(() => {
  //         window.location.reload();
  //       });
  //     }
  //   }
  // }

  // using router
  submit(category): any {
    let params = {};
    params['mainCategory'] = category;
    this.getSomeResults()
    // if (params) {
    this.router.navigate(['/browse'], { queryParams: params });
    // }
    // if (this.formGroupCategory.valid) {
    //   this.categoryData.filters.forEach((filter) => {
    //     if (filter.type == 'tags') {
    //       this.formGroupCategory.controls[filter.key].setValue(
    //         this.categoryData.filters.find((x) => x.key == filter.key)
    //           .tagsValues
    //       );
    //     }
    //     params[filter.key] = this.formGroupCategory.controls[filter.key].value;
    //   });
    //   if (params) {
    //     this.router.navigate(['/browse'], { queryParams: params });
    //   }
    // }
  }

  openExpertPublicProfile(id): any {

    this.dialog.open(ExpertPublicProfileComponent, {
      width: '800px',
      height: '700px',
      data: { entityId: id }
    })
      .afterClosed()
      .subscribe(() => { });
  }

  removeFilters(category) {
    let params = {};
    params['mainCategory'] = category;
    this.formGroupCategory.reset();
    this.router.navigate(['/browse'], { queryParams: params })
      .then(() => this.getCategoryData(category));
  }

  onTabChanged($event) {
    let clickedIndex = $event.index;
    const category = this.categoriesNames[clickedIndex];
    this.selectedCategory = category;
    this.formGroupCategory.reset();
    let queryParams = {};
    queryParams['mainCategory'] = category; // Ensure 'category' is set to 'project' for your desired URL

    this.router.navigate(['/browse'], { queryParams: queryParams })
      .then(() => this.getCategoryData(category));
  }

  async prepareCategoryData(category, filterValues) {
    let filters = [];
    let results = [];
    let isProfile = false;
    switch (category) {
      case 'contest': {
        filters = [
          {
            key: 'category',
            name: 'Category',
            type: 'dropdown',
            dropdownValues: await this.getContestCategories(),
          },
          {
            key: 'subcategory',
            name: 'Subcategory',
            type: 'dropdown',
            dropdownValues: await this.getContestSubCategories(),
          },
          {
            key: 'education',
            name: 'Education needed',
            type: 'tags',
            tagsValues: [],
            selectable: true,
            removable: true,
            addOnBlur: true,
          },
          {
            key: 'skills',
            name: 'Skills needed',
            type: 'tags',
            tagsValues: [],
            selectable: true,
            removable: true,
            addOnBlur: true,
          },
          {
            key: 'reward',
            name: 'Reward',
            type: 'text',
          },
          {
            key: 'keywords',
            name: 'Keywords',
            type: 'tags',
            tagsValues: [],
            selectable: true,
            removable: true,
            addOnBlur: true,
          },
        ];
        const filteredData = await this.filterContests(filterValues);
        results = filteredData?.map((data) => {
          return {
            title: data.title,
            abstract: data.abstract,
            category: data.maincategory,
            subCategory: data.subcategory,
            contestid: data.contestid,
            ownerid: data.ownerid,
            keywords: data.keywords?.length ? data.keywords.map(key => key.keyword) : [],
            rewardHeader: data.rewardheader,
            reward: data.reward,
            requirements: data.requirements,
          };
        });
        break;
      }

      case 'project': {
        filters = [
          {
            key: 'category',
            name: 'Category',
            type: 'dropdown',
            dropdownValues: await this.getProjectCategories(),
          },
          {
            key: 'subcategory',
            name: 'Subcategory',
            type: 'dropdown',
            dropdownValues: await this.getProjectSubCategories(),
          },
          {
            key: 'education',
            name: 'Education needed',
            type: 'text',
          },
          {
            key: 'skills',
            name: 'Skills needed',
            type: 'tags',
            tagsValues: [],
            selectable: true,
            removable: true,
            addOnBlur: true,
          },
          {
            key: 'reward',
            name: 'Reward',
            type: 'text',
          },
          {
            key: 'keywords',
            name: 'Keywords',
            type: 'tags',
            tagsValues: [],
            selectable: true,
            removable: true,
            addOnBlur: true,
          },
        ];
        const filteredData = await this.filterProjects(filterValues);
        results = filteredData?.map((data) => {
          return {
            title: data.title,
            abstract: data.abstract,
            category: data.maincategory,
            subCategory: data.subcategory,
            projectid: data.projectid,
            ownerid: data.ownerid,
            keywords: data.keywords?.length ? data.keywords.map(key => key.keyword) : [],
            rewardHeader: data.rewardheader,
            reward: data.reward,
            requirements: data.requirements,
          };
        });
        break;

      }

      case 'article': {
        filters = [
          {
            key: 'category',
            name: 'Industry / category',
            type: 'dropdown',
            dropdownValues: await this.getArticleCategories(),
          },
          {
            key: 'author',
            name: 'Author',
            type: 'text',
          },
          {
            key: 'keywords',
            name: 'Keywords',
            type: 'tags',
            tagsValues: [],
            selectable: true,
            removable: true,
            addOnBlur: true,
          },
        ];
        const filteredData = await this.filterArticles(filterValues);
        results = filteredData?.map((data) => {
          return {
            title: data.title,
            abstract: data.abstract,
            articleid: data.articleid,
            ownerid: data.ownerid,
            category: data.category,
          };
        });
        break;
      }

      case 'entity': {
        filters = [
          {
            key: 'orgname',
            name: 'Name',
            type: 'text',
          },
          {
            key: 'industry',
            name: 'Industry',
            type: 'dropdown',
            dropdownValues: await this.getEntityIndustries()
          },
          {
            key: 'description',
            name: 'Description',
            type: 'text',
          },
        ];

        const filteredData = await this.filterEntities(filterValues);
        results = filteredData?.map((data) => {
          if (data.draft === 0) {
            return {
              eid: data.eid,
              title: data.orgname,
              abstract: data.description,
              category: data.industry,
            };
          }
        });
        break;
      }

      case 'talent': {
        filters = [
          {
            key: 'firstname',
            name: 'First Name',
            type: 'text',
          },
          {
            key: 'lastname',
            name: 'Last Name',
            type: 'text',
          },
          {
            key: 'email',
            name: 'Email',
            type: 'text'
          },
          // {
          //   key: 'university',
          //   name: 'University',
          //   type: 'text',
          // },
          // {
          //   key: 'degree',
          //   name: 'Degree',
          //   type: 'text',
          // },
          // {
          //   key: 'field_of_study',
          //   name: 'Field of study',
          //   type: 'text',
          // },
          // {
          //   key: 'certification',
          //   name: 'Certification',
          //   type: 'text',
          // },
          // {
          //   key: 'company',
          //   name: 'Company',
          //   type: 'text',
          // },
          // {
          //   key: 'skills',
          //   name: 'Skills',
          //   type: 'text',
          // },
        ];

        const filteredData = await this.filterExperts(filterValues);
        results = filteredData?.map((data) => {
          return {
            id: data.id,
            userid: data.userid,
            firstName: data.firstname,
            lastName: data.lastname,
            email: data.email,
            motivation: data.motivation,
            university: data.entityname,
            degree: data.degree,
            field_of_study: data.fieldofstudy,
            company: data.company,
            certification: data.certid,
          };
        });

        isProfile = true;
        break;
      }

      case 'mentor': {
        filters = [
          {
            key: 'university',
            name: 'University',
            type: 'text',
          },
          {
            key: 'degree',
            name: 'Degree',
            type: 'text',
          },
          {
            key: 'field_of_study',
            name: 'Field of study',
            type: 'text',
          },
          {
            key: 'award',
            name: 'Certification',
            type: 'text',
          },
          {
            key: 'keywords',
            name: 'Keywords',
            type: 'tags',
            tagsValues: [],
            selectable: true,
            removable: true,
            addOnBlur: true,
          },
        ];

        const filteredData = await this.filterMentors(filterValues);
        results = filteredData?.map((data) => {
          return {
            id: data.id,
            firstName: data.firstname,
            lastName: data.lastname,
            email: data.email,
            motivation: data.motivation,
            university: data.entityname,
            degree: data.degree,
            fieldOfStudy: data.fieldofstudy,
            company: data.organization,
            certification: data.certid,
          };
        });

        isProfile = true;
        break;
      }
    }

    const categoryObject = { name: category, filters, results: results ?? [], isProfile };
    return categoryObject;
  }

  add(event: MatChipInputEvent, key): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.categoryData.filters
        .find((x) => x.key == key)
        .tagsValues.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag, key): void {
    const tagsValues = this.categoryData.filters.find(
      (x) => x.key == key
    ).tagsValues;
    const index = tagsValues.indexOf(tag);

    if (index >= 0) {
      tagsValues.splice(index, 1);
    }

    this.categoryData.filters.find((x) => x.key == key).tagsValues = tagsValues;
  }

  getContestCategories(): any {
    return this.contestService.getCategories().toPromise();
    // if (response) {
    //   const categories = response.data.maincategories.map(
    //     (item) => item.mcategory
    //   );
    //   return categories;
    // }
  }

  getContestSubCategories(): any {
    return this.contestService.getSubCategories({}).toPromise();
    // let response = await this.contestService.getSubCategories({}).toPromise();
    // if (response) {
    //   const categories = response.data.subcategories.map(
    //     (item) => item.scategory
    //   );
    //   return categories;
    // }
  }

  getProjectCategories(): any {
    return this.projectService.getCategories().toPromise();
    //   let response = await 
    //   if (response) {
    //     const categories = response.data.maincategories.map(
    //       (item) => item.mcategory
    //     );
    //     return categories;
    //   }
  }

  getProjectSubCategories(): any {
    return this.projectService.getSubCategories({}).toPromise();
    // let response = await this.projectService.getSubCategories({}).toPromise();
    // if (response) {
    //   const categories = response.data.subcategories.map(
    //     (item) => item.scategory
    //   );
    //   return categories;
    // }
  }

  getEntityIndustries(): any {
    return this.profileService.searchEntityIndustries({}).toPromise()
  }


  getArticleCategories(): any {
    return this.articleService.getCategories({}).toPromise();
    // let response = await this.articleService.getCategories({}).toPromise();
    // if (response) {
    //   const categories = response.data.categories.map((item) => item.cat);
    //   return categories;
    // }
  }

  async filterEntities(filterValues) {
    let results: any;
    await this.profileService
      .filterEntities(filterValues)
      .toPromise()
      .then((response) => {
        results = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  }

  async filterArticles(filterValues) {
    if (filterValues['keywords'] && !Array.isArray(filterValues['keywords'])) {
      filterValues['keywords'] = [filterValues['keywords']];
    }

    let results: any;
    await this.articleService
      .filterArticles(filterValues)
      .toPromise()
      .then((response) => {
        results = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  }

  async filterContests(filterValues) {
    if (filterValues['keywords'] && !Array.isArray(filterValues['keywords'])) {
      filterValues['keywords'] = [filterValues['keywords']];
    }
    if (filterValues['skills'] && !Array.isArray(filterValues['skills'])) {
      filterValues['skills'] = [filterValues['skills']];
    }
    if (filterValues['education'] && !Array.isArray(filterValues['education'])) {
      filterValues['education'] = [filterValues['education']];
    }

    let results: any;
    await this.contestService
      .filterContests(filterValues)
      .toPromise()
      .then((response) => {
        results = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  }

  async filterProjects(filterValues) {
    if (filterValues['keywords'] && !Array.isArray(filterValues['keywords'])) {
      filterValues['keywords'] = [filterValues['keywords']];
    }
    if (filterValues['skills'] && !Array.isArray(filterValues['skills'])) {
      filterValues['skills'] = [filterValues['skills']];
    }

    if (
      filterValues['education'] &&
      !Array.isArray(filterValues['education'])
    ) {
      filterValues['education'] = [filterValues['education']];
    }

    let results: any;
    await this.projectService
      .filterProjects(filterValues)
      .toPromise()
      .then((response) => {

        if (typeof response.data === 'string') {
          results = JSON.parse(response.data);
        } else {
          results = response.data;
        }

        console.log(results)

      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  }

  async filterMentors(filterValues) {
    let results: any;
    await this.profileService
      .filterMentors(filterValues)
      .toPromise()
      .then((response) => {
        results = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return results;
  }

  async filterExperts(filterValues) {
    if (filterValues['skills'] && !Array.isArray(filterValues['skills'])) {
      filterValues['skills'] = [filterValues['skills']];
    }
    let results: any;
    await this.profileService
      .filterExperts(filterValues)
      .toPromise()
      .then((response) => {
        results = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return results;
  }

  redirectToResult(result) {
    console.log("NAVIGATING TOOO ??", result, this.selectedCategory)
    if (this.selectedCategory === 'article') {
      this.router.navigate(['article/read', { id: result.articleid, entityId: result.ownerid }]).then();
    } else if (this.selectedCategory === 'project') {
      this.router.navigate(['project/read', { id: result.projectid, entityId: result.ownerid }]).then();
    } else if (this.selectedCategory === 'contest') {
      this.router.navigate(['contest/read', { id: result.contestid, entityId: result.ownerid }]).then();
    } else if (this.selectedCategory === 'talent') {
      this.router.navigate(['/message', { entityId: result.id }]).then();
    } else if (this.selectedCategory === 'expert') {
      this.router.navigate(['/message', { entityId: result.id }]).then();
    }

    return;
  }
}
