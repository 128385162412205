<div class="talent-search-card-wrapper">
    <div class="contact-talent">
        <h2>{{ result?.firstname }} {{ result?.lastname }}</h2>
        <div class="talent-action-btns">
            <button mat-button (click)="openExpertPublicProfile(result.id)" class="contact-talent-btn~"
                type="button">View
                talent</button>
            <button mat-flat-button color="primary" (click)="addContact(result)" class="contact-talent-btn~"
                type="button">Contact talent</button>
        </div>
    </div>
    <div style="margin-top: 20px">{{ result?.motivation }}</div>

    <div style="margin-top: 10px">
        <b>Degree</b>: {{ result?.degree ? result?.degree : 'No degree listed' }}
    </div>
    <div style="margin-top: 10px">
        <b>Field of Study</b>: {{ result?.field_of_study ? result?.field_of_study : 'No field of study listed'
        }}
    </div>
    <div style="margin-top: 10px" *ngIf="result?.company">
        <b>Company</b>: {{ result?.company }}
    </div>
    <div style="margin-top: 10px" *ngIf="result?.certification">
        <b>Certificate</b>: {{ result?.certification }}
    </div>
</div>