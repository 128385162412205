import { Component, Input, OnInit } from '@angular/core';
import { ExpertPublicProfileComponent } from '../expert-public-profile/expert-public-profile.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddContactModalComponent } from '../add-contact-modal/add-contact-modal.component';

@Component({
  selector: 'app-search-talent-card',
  templateUrl: './search-talent-card.component.html',
  styleUrls: ['./search-talent-card.component.scss']
})
export class SearchTalentCardComponent implements OnInit {
  @Input() result;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openExpertPublicProfile(id) {
    console.log(id);

    this.dialog.open(ExpertPublicProfileComponent, {
      width: '800px',
      height: '700px',
      data: { entityId: id }
    })
      .afterClosed()
      .subscribe(() => { });
  }
  addContact(data): any {
    const dialogRef: MatDialogRef<AddContactModalComponent> = this.dialog.open(AddContactModalComponent, {
      width: '800px',
      height: '300px',
      data: data,
      autoFocus: false,
    });

  }

}
